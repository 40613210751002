export default [
    {
        title: 'Assets',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        title: 'Map',
        route: 'map',
        icon: 'MapIcon',
    },
    {
        title: 'Users',
        route: 'list-user',
        icon: 'UserIcon',
    },
    {
        title: 'Customers',
        route: 'list-customer',
        icon: 'UsersIcon',
    },
    {
        title: 'Dealers',
        route: 'list-dealer',
        icon: 'UserCheckIcon',
    },
    {
        title: 'Devices',
        route: 'list-device',
        icon: 'HardDriveIcon',
    },
    
    {
        title: 'Drivers',
        route: 'list-driver',
        icon: 'TruckIcon',
    },
    
    {
        title: 'Reports',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'Measurement',
                route: 'report-usage'
            },
            {
                title: 'Usage Profile',
                route: 'assets-dashboards',
            },
        ]
    },
    {
        title: 'Management',
        icon: 'SettingsIcon',
        children: [
          {
            title: 'Fuelling',
            route: 'list-fuelling',
          },
          {
            title: 'Maintenance Plan',
            route: 'list-maintenance-plan',
          },
          {
            title: 'Group',
            route: 'list-group',
          },
          
        ],
    },
]
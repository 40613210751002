import store from '@/store'




export default async function getCustomerSettings() {

    
    var customerSettings = JSON.parse(localStorage.getItem('customerSettings'));
    if (!customerSettings) {
        const res = await store.dispatch('app-system/fetchUserSettings')
       //console.log()
        customerSettings = await res.data ? res.data : '';
        if(customerSettings && customerSettings !== null && customerSettings !== undefined) localStorage.setItem('customerSettings', JSON.stringify(customerSettings));
        
            
    }
    console.log('SETTINGS', customerSettings)
    return customerSettings
}






import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSearch(ctx, query) {
      //let q = query.query
      console.log(`query --- `, query)
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/user/users', { params: queryParams })
          .get(`/api/v1/System/search?Search=${query}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvents(ctx) {
      //let q = query.query
      
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/user/users', { params: queryParams })
          .get(`/api/v1/System/events`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRemoveAlert(ctx, id) {
      //let q = query.query
      
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/user/users', { params: queryParams })
          .post(`/api/v1/Event/seen?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUserSettings(ctx){
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/user/users', { params: queryParams })
          .get(`/api/v1/Customer/settings`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
    
  },
}

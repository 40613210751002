<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      
      

      <vue-autosuggest
      v-if="showSearchBar"
      @keyup.esc="showSearchBar = false; resetsearchQuery()"
      @keyup.enter="suggestionSelected"
      
      v-model="searchQuery"
      :suggestions="filteredOptions"
      :input-props="inputProps"
      @selected="selectHandler"
      
      @input="onInputChange"
    >
    <template slot-scope="{suggestion}">
        <span class="my-suggestion-item suggestion-group-suggestion cursor-pointer">{{ suggestion.item.name }}</span>
      </template>
    </vue-autosuggest>
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>

    </div>
  </li>
</template>

<script>
import {
  BFormInput, BLink, BImg, BAvatar,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { title } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
import searchAndBookmarkData from '../search-and-bookmark-data'

import { VueAutosuggest } from 'vue-autosuggest'
import systemStoreModule from '../systemStoreModule'

export default {
  components: {
    BFormInput,
    BLink,
    BImg,
    BAvatar,
    VuePerfectScrollbar,
    VueAutosuggest
  },
  data(){
    return{
      
      searchQuery:'',

      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search ...",
      },
      limit: 3,
      selected: null,
    }
  },
  created() {
        const SYSTEM_APP_STORE_MODULE_NAME = 'app-system'

        // Register module
        if (!store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.registerModule(SYSTEM_APP_STORE_MODULE_NAME, systemStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.unregisterModule(SYSTEM_APP_STORE_MODULE_NAME)
        })
  },
  methods: {
    resetsearchQuery(){
      this.searchQuery = ''
      this.filteredOptions = []
    },
    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchQuery = ''
      this.showSearchBar = false;

      if(option.item.resultType == 'asset'){

        router.push({ name: 'preview-asset', params: { id: option.item.id } })
      }
      else if(option.item.resultType == 'user'){
        router.push({ name: 'register-user', params: { id: option.item.id } })
      }
      else if(option.item.resultType == 'customer'){
        router.push({ name: 'register-customer', params: { id: option.item.id } })
      }
      else if(option.item.resultType == 'device'){
        router.push({ name: 'register-device', params: { id: option.item.id } })
      }
    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {
        return
      }

      
      store.dispatch('app-system/fetchSearch', this.searchQuery)
                .then(response => {
                  console.log('SEARCH GLOBAL',response.data.data)
                  this.filteredOptions = [{
                      data: response.data.data,
                  }]
                  
                })
                .catch(error => {
                    
                       console.log(error)
                    
                })
    },
    suggestionSelected(){
      console.log('Selecionado', this.selected )
    }
  },
  setup() {
    const showSearchBar = ref(false)

    return{
      showSearchBar
    }
  }
  
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>

<template>
<b-nav-item-dropdown ref="dropdown" class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
        <feather-icon :badge="systemNotifications.length" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" v-if="systemNotifications && systemNotifications.length > 0" />
        <feather-icon badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" v-else />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">
                {{$t('Notifications')}}
            </h4>
            <b-badge pill variant="light-primary">
                {{systemNotifications.length}} New
            </b-badge>
        </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="li">
        <!-- System Notifications -->
        <b-link v-for="notification in systemNotifications" :key="notification.id" @click="hendleClickAlarm($event, notification.assetId, notification.id)">
            
            <b-media>
                <template #aside>
                    <b-avatar size="32" variant="light-info">
                        <feather-icon icon="InfoIcon" />
                    </b-avatar>
                </template>
                <p class="media-heading">
                    <span class="font-weight-bolder">
                        {{ notification.name }}
                    </span>
                </p>
                <b-badge class="btn-mark mt-1 avoid-click" variant="primary" @click="removeAlert(notification.id)">
                    {{$t('Mark as seen')}}
                </b-badge>
                
            </b-media>

        </b-link>
    </vue-perfect-scrollbar>

    <!--
                <b-link v-for="notification in systemNotifications" :key="notification.id" @click="removeAlert(notification.id)">
            <b-media>
                <template #aside>
                    <b-avatar size="32" variant="light-info">
                        <feather-icon icon="InfoIcon" />
                    </b-avatar>
                </template>
                <p class="media-heading">
                    <span class="font-weight-bolder">
                        {{ notification.name }}
                    </span>
                </p>
               <small class="notification-text">{{ notification.subtitle }}</small>
            </b-media>
    -->

    <!-- Cart Footer 
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li> -->
</b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
    BRow,
    BCol
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import systemStoreModule from '../systemStoreModule'
import router from '@/router'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BAvatar,
        VuePerfectScrollbar,
        BButton,
        BFormCheckbox,
        BRow,
        BCol,
        ToastificationContent
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            systemNotifications: []
        }
    },
    methods: {
        closeDropdown() {
            this.$refs.dropdown.hide()
        },
        toAlarms(assetId){
            this.$router.push({ name: 'settings-alarms', params: { id: assetId } })
        },

        hendleClickAlarm(event, assetId, alarmId) {
            
            if (!event.target.classList.contains('avoid-click')) {
                
                this.toAlarms(assetId)
                this.removeAlert(alarmId)
                this.closeDropdown()
            }
        },


        removeAlert(id) {
            
            this.closeDropdown()
            store.dispatch('app-system/fetchRemoveAlert', id)
                .then(response => {
                    //this.systemNotifications = []
                    this.fetchEvents()

                })
                .catch(error => {

                    console.log(error);
                    //console.log(error.response.status);
                    //alert(error.response.status)
                })
        },

        fetchEvents() {
            this.systemNotifications = []
            store.dispatch('app-system/fetchEvents')
                .then(response => {

                    this.$nextTick(() => {
                        this.systemNotifications = response.data.data
                        console.log('ALERTAS === ', this.systemNotifications)

                    })
                })
                .catch(error => {

                    console.log(`--- erro no fetchEvents ---`)
                    console.log(error)

                })
        },

        registerEventBusListeners: function () {
            this.$root.$on("evt", this.event_evt);
        },

        deregisterEventBusListeners: function () {
            this.$root.$off("evt", this.event_evt);
        },

        
        event_evt: function (content) {
                       
            this.fetchEvents()
            console.log('EVT: ', content)
            this.showAlert(content.assetDescriptor, content.type.name)
        },

    },
    created() {
        this.fetchEvents()
    },
    mounted() {
        this.registerEventBusListeners();
    },
    beforeDestroy() {
        this.deregisterEventBusListeners();
    },
    setup() {
        /* eslint-disable global-require */
        const SYSTEM_APP_STORE_MODULE_NAME = 'app-system'

        // Register module
        if (!store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.registerModule(SYSTEM_APP_STORE_MODULE_NAME, systemStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.unregisterModule(SYSTEM_APP_STORE_MODULE_NAME)
        })

        const toast = useToast()
        const show = ref(false);

        const showAlert = (asset, alert) => {
           
            if(show.value) return

            show.value = true;

            setTimeout(() => {
                show.value = false;
            }, 1000);


            toast({
                component: ToastificationContent,
                props: {
                    title: alert,
                    text: `Ativo: ${asset}`,
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                },
            })
        }

        //const systemNotifications = ref([])

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        return {
            SYSTEM_APP_STORE_MODULE_NAME,
            //systemNotifications,
            perfectScrollbarSettings,
            showAlert
        }
    },
}
</script>

<style scoped>
.btn-mark:hover{
    box-shadow: 0px 3px 10px #7367f0de;
}

</style>

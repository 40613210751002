<template>
  <p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT © {{ new Date().getFullYear() }}
    <b-link
      class="ml-25"
      href="https://www.onboard.ind.br/"
      target="_blank"
    >
      <span class="text-bold" style="color: #24ce47;">On</span><span class="text-bold" style="color: #000000 !important;">Board</span>
    </b-link>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>

  <span class="float-md-right d-none d-md-block" >
    📡
  </span>
</p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
